import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Box, DialogActions } from "@mui/material";
import { Close } from "@mui/icons-material";

export default function BasicDialog({
  children,
  title,
  open,
  handleClose,
  fullScreen,
  secondaryAction,
  showCancel = false,
  sx,
}) {
  return (
    <Dialog
      fullScreen={fullScreen}
      sx={{ ...sx }}
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" display={"flex"} gap={4}>
        {title}{" "}
        {showCancel && (
          <Close sx={{ cursor: "pointer" }} onClick={handleClose} />
        )}
      </DialogTitle>

      <DialogContent>{children}</DialogContent>
    </Dialog>
  );
}
