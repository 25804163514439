import React from "react";
import { Box, Button } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { navItems, userTypes } from "../utils/menuItems";

const types = {
  ...userTypes,
  sup_admin: "Super Admin",
  sub_admin: "Sub Admin",
};

const Navbar = () => {
  const { pathname } = useLocation();
  const { auth } = useSelector((state) => state.auth);

  return (
    <Box sx={{ p: "1.5rem", mb: 1 }}>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Box>
          <Box sx={{ display: "flex", alignItems: "end", gap: 1 }}>
            <Box
              sx={{
                fontWeight: "bold",
                fontSize: "2.1rem",
                "@media(max-width: 600px)": {
                  fontSize: "1.1rem !important",
                },
              }}
            >
              {auth?.name || "NA"}
            </Box>
            <Box sx={{ color: "#BFBFBF", fontSize: 20 }}>
              {types[auth?.role] || "NA"}
            </Box>
          </Box>
          <Box sx={{ fontSize: 15 }}>{auth?.email || "NA"}</Box>
        </Box>

        <Box
          sx={{
            ml: "auto",
            display: "flex",
            alignItems: "center",
            gap: "2rem",
            "@media (max-width: 900px)": {
              display: "none",
              "@media (max-width: 600px)": {
                display: "none",
              },
            },
          }}
        >
          {navItems
            .filter((item) => {
              if (
                auth?.role === "agent" &&
                (item.title === "Users" || item.title === "Teams")
              ) {
                return false;
              } else if (
                (auth?.role === "sup_admin" ||
                  auth?.role === "sub_admin" ||
                  auth?.role === "sr_manager") &&
                item.title === "Teams"
              ) {
                return false;
              } else if (
                (auth?.role === "manager" ||
                  auth?.role === "assistant_manager" ||
                  auth?.role === "team_lead") &&
                item.title === "Users"
              ) {
                return false;
              } else if (
                auth?.role !== "sup_admin" &&
                auth?.role !== "sub_admin" &&
                item.title === "Projects"
              ) {
                return false;
              } else {
                return item;
              }
            })
            .map((item) =>
              pathname.includes(item.url) ? (
                <Link to={item.url} key={item.url}>
                  <Button
                    variant="contained"
                    color="custom"
                    startIcon={item.icon}
                    sx={{ fontWeight: "bold", textTransform: "unset" }}
                  >
                    {item.title}
                  </Button>
                </Link>
              ) : (
                <Link to={item.url} key={item.url}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      fontWeight: "bold",
                      gap: 1,
                      p: 1,
                      borderRadius: 2,
                    }}
                  >
                    {item.icon}
                    <Box>{item.title}</Box>
                  </Box>
                </Link>
              )
            )}
        </Box>
      </Box>
    </Box>
  );
};

export default Navbar;
