export let baseUrl = process.env.REACT_APP_BASE_URL;
// export let baseUrl = "https://axproperty-api-new.onrender.com/api/";
// export let baseUrl = "https://axproperty-api-project-branch.onrender.com/api/"
// export let baseUrl = "http://localhost:5000/api/";
// let baseUrl = "https://293b-2409-4085-879f-e246-818-2c67-2651-66a.ngrok-free.app/api/"

const Apis = {
  baseUrl,
  login: baseUrl + "auth/login",
  users: baseUrl + "user",
  leads: baseUrl + "lead",
  meeting: baseUrl + "meeting",
  team: baseUrl + "team",
  booking: baseUrl + "booking",
  developer: baseUrl + "developer",
  project: baseUrl + "project",
  getLeadById: baseUrl + "lead/getLeadById",
  meetingDetailsById: baseUrl + "meeting/meetingDetailsById",
  dashboard: baseUrl + "dashboard",
  meetingDashboard: baseUrl + "dashboard/meetings",
  bookingDashboard: baseUrl + "dashboard/bookings",
  getBookingDetailsById: baseUrl + "booking/getBookingDetailsById",
  getMeetingForBooking: baseUrl + "meeting/getMeetingForBooking",
  assignUsers: baseUrl + "user/assigningUsers",
  developerUpdateById: baseUrl + "developer/developerUpdateById",
  userUpdateById: baseUrl + "user/userUpdateById",
  leadUpdateById: baseUrl + "lead/leadUpdateById/",
  statusUpdateById: baseUrl + "lead/statusUpdateById/",
  teamUpdatedById: baseUrl + "team/teamUpdateById/",
  leadAssignById: baseUrl + "lead/leadAssignById",
  updateMeetingById: baseUrl + "meeting/updateMeetingById/",
  deleteMeetingsById: baseUrl + "meeting/deleteMeetingsById",
  bookingApprovedById: baseUrl + "booking/bookingApprovedById",
  bookingRejectedById: baseUrl + "booking/bookingRejectedById",
  deleteLeadsByIds: baseUrl + "lead/deleteLeadsByIds",
  deleteBookingsById: baseUrl + "booking/deleteBookingsById",
  changePassword: baseUrl + "user/changePassword",
  setPassword: baseUrl + "user/setPassword",
  meetingReschedule: baseUrl + "meeting/reScheduleMeetingById",
  meetingConduct: baseUrl + "meeting/conductById",
  notificationSeenById: baseUrl + "user/notificationSeenById",
  getNotificationById: baseUrl + "user/getNotificationById",
  getCommissionData: baseUrl + "booking/getCommissionData",
  filterLeads: baseUrl + "lead/filterLeads",
  filterMeetings: baseUrl + "meeting/filterMeetings",
  filterBookings: baseUrl + "booking/filterBookings",
  meetingOtpVerify: baseUrl + "meeting/meetingOtpVerify",
  meetingOtpGenerate: baseUrl + "meeting/meetingOtpGenerate",
  caseFileUpload: baseUrl + "booking/caseFileUpload",

  /* Hr Management */
  hrmsUser: baseUrl + "hrms/user",
  getTeamBySrManager: baseUrl + "hrms/user/getTeamsBySrManager",
  getAvailableRoles: baseUrl + "hrms/user/getAvailableRoles",
  hrmsLeave: baseUrl + "hrms/leave",
  getHrmsUserDetails: baseUrl + "hrms/user/getUserDetailsById",
  setAdvanceLeaves: baseUrl + "hrms/user/setAdvanceLeaves",
  getHrmsLeaveDetails: baseUrl + "hrms/leave/getDetailsById",
  sendInvitation: baseUrl + "hrms/user/sendInvitation",
  accessUpdate: baseUrl + "hrms/user/accessUpdate",
  updateUser: baseUrl + "hrms/user/updateUserById",
  hrmsLeaveDasbordData: baseUrl + "hrms/leave/dashboardData",
  hrmsLeaveApprove: baseUrl + "hrms/leave/leaveApproveById",
  hrmsLeaveReject: baseUrl + "hrms/leave/leaveRejectById",
  hrmsUserApprove: baseUrl + "hrms/user/approveById",
  hrmsUserDasbordData: baseUrl + "hrms/user/dashboardData",
  todaysMeetings: baseUrl + "meeting/getTodaysMeetings",
  getDeveloperOptions: baseUrl + "developer/getDeveloperOptions",
  remotePunch: baseUrl + "hrms/att/remotePunch",
  getAttendance: baseUrl + "hrms/att",
  hrmsRaiseIssues: baseUrl + "hrms/att/issueRaiseById",
  getAttDetails: baseUrl + "hrms/att/getDetailsById",
  attUpdateStatus: baseUrl + "hrms/att/updateStatusById",
  // Attendance Dashboard api
  hrmsDashboard: baseUrl + "hrms/dashboard",
  hrmsLeaveCancel: baseUrl + "hrms/leave/leaveCancelById",
};

export default Apis;
