import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithAuth } from "./hrmsNotifications";
import Apis from "../../utils/apis";

export const projectDetailsApi = createApi({
  reducerPath: "projectDetails",
  baseQuery: baseQueryWithAuth,
  tagTypes: ["ProjectDetail"],
  endpoints: (builder) => ({
    getProjectDetailsById: builder.query({
      query: (id) => ({
        url: `${Apis.project}/${id}`,
      }),
      providesTags: ["ProjectDetail"],
      keepUnusedDataFor: 300,
    }),
  }),
});



export const {  useGetProjectDetailsByIdQuery } = projectDetailsApi;
